import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import * as styles from "../styles/details.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { Fade } from "react-reveal"
import SEO from "../components/Seo"
import slider1 from '../images/works/slider/1.jpg'
import slider3 from '../images/works/slider/3.jpg'
import slider2 from '../images/works/slider/2.jpg'

export default function ProjectsDetails({ data }) {
  const options = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: "none",
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: false,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: "linear",
      overlayColor: "rgba(30, 30, 30, 0.9)",
      slideAnimationType: "fade",
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: "linear",
      usingPreact: false,
    },
    caption: {
      showCaption: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
  }

  const {
    title,
    featuredImg,
    country,
    directors,
    duration,
    genres,
    pub,
    state,
    synopsisSpanish,
    synopsisEnglish,
    haveSlider
  } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SEO
        title={title}
        image={featuredImg.childImageSharp.gatsbyImageData.images.fallback.src}
        description={synopsisEnglish}
      />
      <Container className={styles.containerDetails}>
        <Fade right>
          <h2>{title}</h2>
        </Fade>
        <Fade left>
          <Row>
            <Col md={6}>
              <div className={styles.containerDetailsImage}>
                {
                  haveSlider ? 
                  <div className={styles.slider}>
                          <figure>
                              <img src={slider1} />
                              <img src={slider2} />
                              <img src={slider3} />
                          </figure>
                        </div>: 
                <GatsbyImage
                  image={featuredImg.childImageSharp.gatsbyImageData}
                  alt={`imagen ${title}`}
                />
                }
              </div>
            </Col>
            <Col md={6} style={{ marginTop: "auto" }}>
              <h3>Ficha Técnica</h3>
              <div className={styles.containerBlockText}>
                <div className={styles.blockText}>
                  <p className={styles.blockTextTitle}>Género:</p>
                  <p className={styles.blockTextDetail}>{genres}</p>
                </div>
                <div className={styles.blockText}>
                  <p className={styles.blockTextTitle}>Duración:</p>
                  <p className={styles.blockTextDetail}>{duration}</p>
                </div>
                <div className={styles.blockText}>
                  <p className={styles.blockTextTitle}>Dirección:</p>
                  <p className={styles.blockTextDetail}>{directors}</p>
                </div>
                <div className={styles.blockText}>
                  <p className={styles.blockTextTitle}>Publico:</p>
                  <p className={styles.blockTextDetail}>{pub}</p>
                </div>
                <div className={styles.blockText}>
                  <p className={styles.blockTextTitle}>Origen:</p>
                  <p className={styles.blockTextDetail}>{country}</p>
                </div>
                <div className={styles.blockText}>
                  <p className={styles.blockTextTitle}>Estado:</p>
                  <p className={styles.blockTextDetail}>{state}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Fade>
        <Fade bottom>
          <Row className="my-3">
            <Col md={6}>
              <h3>Sinopsis</h3>
              <p className={styles.text}>{synopsisSpanish}</p>
            </Col>
            <Col md={6}>
              <h3>Synopsis</h3>
              <p className={styles.text}>{synopsisEnglish}</p>
            </Col>
          </Row>
        </Fade>
        <Fade bottom cascade>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <Row className={styles.rowImage}>
                {data.gallery &&
                  data.gallery.edges &&
                  data.gallery.edges.length &&
                  data.gallery.edges.map(({ node }) => (
                    <Col lg={4} md={6} key={node.id} className="py-3">
                      <a href={node.publicURL}>
                        <GatsbyImage
                          image={node.childImageSharp.gatsbyImageData}
                          alt={node.base.split("-").join(" ").split(".")[0]}
                        />
                      </a>
                    </Col>
                  ))}
              </Row>
            </SRLWrapper>
          </SimpleReactLightbox>
        </Fade>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ProjectDetails($slug: String, $reg: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        country
        directors
        duration
        featuredImg {
          childImageSharp {
            gatsbyImageData(quality: 50, transformOptions: { fit: COVER })
          }
        }
        genres
        pub
        slug
        state
        title
        synopsisSpanish
        synopsisEnglish
        haveSlider
      }
    }
    gallery: allFile(filter: { relativeDirectory: { regex: $reg } }) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            gatsbyImageData(transformOptions: { fit: COVER })
          }
        }
      }
    }
  }
`
